export function formatNumber(
  value: number,
  precision?: number,
  caseZero?: string
) {
  if (value === 0 && caseZero !== undefined) {
    return caseZero;
  }

  return Intl.NumberFormat("pt-BR", {
    style: "decimal",
    maximumFractionDigits: precision ?? 2,
    minimumFractionDigits: precision ?? 2,
  }).format(value);
}

export function formatDate(value: string, style?: "short") {
  const d = new Date(value);
  return Intl.DateTimeFormat("pt-BR", {
    dateStyle: style || "short",
  }).format(d);
}

export function formatAge(value: number) {
  if (value < 24) {
    return value + "h";
  } else if (value === 24) {
    return "1d";
  } else if (value > 24) {
    const dia = value / 24;
    const horas = value % 24;
    let res = dia.toFixed(0) + "d";
    if (horas > 0) {
      res += horas.toFixed(0) + "h";
    }
    return res;
  }

  return value + "";
}

export function formatCPCode(value: number | string) {
  let code = value.toString().padStart(6, "0");
  const match = code.match(/(\d{3})(\d{3})/);

  if (match) {
    code = match[1] + "." + match[2];
  }

  return code;
}
