<script setup lang="ts">
interface Props {
  label?: string;
  description?: string;
  name?: string;
  value?: string;
}
const id = (Math.random() + 1).toString(36).substring(4);

defineProps<Props>();
const model = defineModel({});
</script>

<template>
  <div class="flex flex-col justify-center">
    <div class="inline-flex items-center gap-3">
      <label class="flex items-center cursor-pointer relative">
        <input
          type="radio"
          class="peer bg-white h-5 w-5 cursor-pointer transition-all appearance-none rounded-full shadow hover:shadow-md border border-secondary-border checked:bg-white checked:border-primary"
          :id="id"
          v-model="model"
          :value="value"
          :name="name"
        />
        <span
          class="absolute rounded-full bg-primary h-3 w-3 text-white opacity-0 peer-checked:opacity-100 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
        />
      </label>
      <label v-if="label" :for="id" class="text-sm cursor-pointer">
        {{ label }}
      </label>
    </div>
    <label v-if="description" class="text-xs text-secondary-text">
      {{ description }}
    </label>
  </div>
</template>
