import type { Me } from "~/types";
import * as api from "~/services/api";
import { logout } from "./services/auth";

const me = ref<Me>();

export function getMe() {
  if (!me.value) {
    throw new Error("User not defined");
  }

  return me.value;
}

export async function setUser() {
  if (me.value) return;

  try {
    const user = await api.me();
    console.log("u", user);
    if (!user?.roles?.includes("manager")) {
      logout();
      return null;
    }

    me.value = user;
    return user;
  } catch (e) {
    return null;
  }
}

export { me };
