//
const host = useRuntimeConfig().public.REPORT_HOST;

function parseParams(
  params: { [key: string]: any },
  arrayFormat: boolean = false
) {
  let stringParams: string = "";

  stringParams = Object.keys(params)
    .map((key) => {
      const paramsValues = params[key];

      if (Array.isArray(paramsValues) && arrayFormat) {
        return paramsValues
          .map((param) => `${key}=${encodeURIComponent(param)}`)
          .join("&");
      } else {
        return `${key}=${encodeURIComponent(params[key] || "")}`;
      }
    })
    .join("&");

  if (stringParams.length > 0) {
    return `?${stringParams}`;
  }

  return "";
}

function stress(params: {
  contract: number;
  application: string;
  token: string;
  molding_start?: string;
  molding_end?: string;
  places?: string[];
  optional_places?: string[];
  series?: string;
  attach_chart?: boolean;
}) {
  let endpoint = new URL(`/stress-simple`, host).href + parseParams(params);

  window.open(endpoint, "_blank");
}

function cpToBreak(params: {
  start_date: string;
  end_date: string;
  application: string;
  token: string;
  control?: number;
}) {
  let endpoint = new URL(`/stress-remains`, host).href + parseParams(params);
  //const endpoint = `${BASE_URL}concrete/remains/${parseParams(params)}`;
  window.open(endpoint, "_blank");
}

export const report = {
  stress,
  cpToBreak,
};
