// @ts-ignore
import everpolate from "everpolate";
import type { ConcreteTestingBody } from "~/types";
import { todayEndTime } from "./utils";
/**
 * Gravity contant
 */
export const G = 9.80655;

/*
 * Conforme item 6.1 da ABNT NBR 5739
 * load: expresso em Kgf
 * diameter: measured in mm
 */
export function compressionFck(load: number, diameter: number, height: number) {
  let correctionFactor = 1;
  if (diameter === 0 || height === 0) {
    return { fck: 0, correctionFactor, g: G };
  }
  // 1000 representa a resolução da prensa, 1unidade para cada 1Kgf
  // Item 6.1.1
  let result = (4 * load * G * 1000) / (Math.pow(diameter, 2) * Math.PI);

  // Item 6.1.2
  if (height / diameter < 1.94) {
    correctionFactor = fckCorrectionFactor(height, diameter);
  }
  result = result * correctionFactor;

  return { fck: parseFloat(result.toFixed(3)), correctionFactor, g: G };
}

function fckCorrectionFactor(height: number, diameter: number) {
  const relation = [2, 1.75, 1.5, 1.25, 1];
  const factor = [1, 0.98, 0.96, 0.93, 0.86];

  const correction: number = everpolate.linear(
    height / diameter,
    relation,
    factor
  )[0];

  if (correction) {
    return parseFloat(correction.toFixed(2));
  }

  return 0;
}

export function ruptureDateIsAhead(cp: ConcreteTestingBody) {
  const rupture = new Date(cp.rupture_date);
  const ahead = Date.now() < rupture.getTime();

  return ahead;
}

export function ruptureDateIsLate(cp: ConcreteTestingBody) {
  const rupture = new Date(cp.rupture_date);
  const late = todayEndTime() > rupture.getTime();

  return late;
}
