import { token } from "./localstorage";

let access_token = "";
let host = "";

export function config(_host: string) {
  host = _host;
}

export function authenticate(token: string) {
  access_token = "Bearer " + token;
}

export async function login(login: string, password: string) {
  try {
    const body = {
      username: login,
      password,
    };

    const url = new URL("login/", host);

    const res = await fetch(url.href, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify(body),
    });

    if (res.ok) {
      const data = await res.json();
      token.dump(data.token);

      return true;
    } else {
      throw new Error("Falha ao fazer login, verifique seu email ou senha");
    }
  } catch (e) {
    throw e;
  }
}

export async function logout() {
  const url = new URL("logout/", host);

  const tokenData = await token.load();

  const body = {
    refresh_token: tokenData?.refresh,
  };

  const res = await fetch(url.href, {
    method: "POST",
    headers: {
      "Content-type": "application/json",
      Authorization: access_token,
    },
    body: JSON.stringify(body),
  });

  if (res.ok) {
    token.delete();
    return Promise.resolve();
  } else {
    return Promise.reject();
  }
}

//const resetPasswordEmail = (email: string) => {
//  const endpoint = `password_reset/`;
//
//  return api.post(endpoint, { email });
//};
//
//const validateToken = (token: string) => {
//  const endpoint = `password_reset/validate_token/`;
//
//  return api.post(endpoint, { token });
//};
//
//const confirmNewPassword = (token: string, password: string) => {
//  const endpoint = `password_reset/confirm/`;
//
//  return api.post(endpoint, { token, password });
//};
