import type {
  ClientRequest,
  ConcreteControlRequest,
  SerieRequest,
} from "~/types/requests";

import type {
  ConcreteSerie,
  Pagenated,
  ConcreteControl,
  Contract,
  ConcreteTestingBody,
  Application,
  Me,
  Work,
  ConcreteSummary,
  ConcreteFCKsSummary,
  User,
  Client,
} from "~/types";
import * as ls from "./localstorage";

let slug = "";
export function setSlug(value: string) {
  slug = value;
}

let token = "";
let host = "";

export function authenticate(_token: string, _host: string) {
  token = "Bearer " + _token;
  host = _host;
}

type QueryString = {
  [key: string]: (string | number)[] | string | number | boolean;
};

export function queryString(params: QueryString) {
  const searchParams = new URLSearchParams();

  Object.keys(params).forEach((key) => {
    const value = params[key];
    if (value !== null && value !== undefined) {
      searchParams.append(key, `${params[key]}`);
    }
  });

  const qs = searchParams.toString();

  return qs ? `?${qs}` : "";
}

async function handleResponse(response: Response) {
  let json;

  const text = await response.text();
  if (text.length > 0) {
    try {
      json = JSON.parse(text);
    } catch (e) {
      return Promise.reject(e);
    }
  }

  if (response.ok) {
    return json;
  } else {
    return Promise.reject({
      status: response.status,
      error: json,
    });
  }
}

async function get<T = any>(path: string, query?: QueryString): Promise<T> {
  let qs = "";
  if (query) {
    qs = queryString(query);
  }

  const url = new URL(path, host);

  const requestOptions = {
    method: "GET",
    query,
    headers: {
      Authorization: token,
    },
  };

  return fetch(url.href + qs, requestOptions).then((r) => {
    if (r.status === 403) {
      ls.token.delete();
    }
    return handleResponse(r);
  });
}

async function post<T = any>(url: string, data?: any): Promise<T> {
  const requestOptions = {
    method: "POST",
    body: JSON.stringify(data),
    headers: {
      Authorization: token,
      "Content-type": "application/json",
    },
  };

  return fetch(url, requestOptions).then(handleResponse);
}

async function put<T = any>(url: string, data?: any): Promise<T> {
  const requestOptions = {
    method: "PUT",
    body: JSON.stringify(data),
    headers: {
      Authorization: token,
      "Content-type": "application/json",
    },
  };

  return fetch(url, requestOptions).then(handleResponse);
}

async function _delete<T = any>(path: string, data?: any): Promise<T> {
  const url = new URL(path, host);

  const requestOptions = {
    method: "DELETE",
    body: JSON.stringify(data),
    headers: {
      Authorization: token,
      "Content-type": "application/json",
    },
  };

  return fetch(url.href, requestOptions).then(handleResponse);
}

const api = {
  get,
  put,
  post,
  delete: _delete,
};

export async function getConcreteControlById(contractId: number) {
  const url = `/${slug}/concretes/v2/controls/${contractId}/`;
  return get<ConcreteControl>(url);
}

export async function getConcreteControls(inactive: boolean) {
  const url = `/${slug}/concretes/v2/controls/`;

  return get<ConcreteControl[]>(url, { inactive: inactive ? "1" : "0" });
}

export async function getConcreteControl(contractId: number) {
  const url = `/${slug}/concretes/controls/${contractId}/`;
  return get<ConcreteControl[]>(url);
}

export async function getSeries(
  controlId: number,
  options: { page: number; series: string; missing_ruptures: boolean }
) {
  const url = `/${slug}/concretes/v2/${controlId}/series_new/`;

  return get<Pagenated<ConcreteSerie>>(url, options);
}

export async function getSerie(id: number) {
  const url = `/${slug}/concretes/v2/series-new/${id}/`;
  return get(url);
}

export async function getCPs(id: number) {
  const url = `/${slug}/concretes/v2/series/${id}/cps/`;
  return get<ConcreteTestingBody[]>(url);
}

export async function postAddCPs(
  id: number,
  data: { code: number; age: number }[]
) {
  const url = `${host}/${slug}/concretes/v2/series/${id}/cps/add/`;
  return post<ConcreteTestingBody[]>(url, data);
}

export async function getCPsToBreak(params?: {
  breaked?: number;
  start_date?: string;
  end_date?: string;
  page?: number;
}) {
  const url = `/${slug}/concretes/v2/cps/`;
  return get<Pagenated<ConcreteTestingBody>>(url, params);
}

export async function getClients(params?: { q?: string }) {
  const url = `/${slug}/clients/`;

  return get<Client[]>(url, params);
}

export async function getWorks(params?: { q?: string }) {
  const url = `/${slug}/works/`;

  return get<Work[]>(url, params);
}

export async function postSeries(id: number, data: SerieRequest) {
  const endpoint = `${host}/${slug}/concretes/${id}/series/`;

  return post<ConcreteSerie>(endpoint, data);
}

export async function putSeries(id: number, data: SerieRequest) {
  const endpoint = `${host}/${slug}/concretes/series/${id}/`;

  const req = await fetch(endpoint, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
    body: JSON.stringify(data),
  });

  return await req.json();
}

export function postControl(contractId: number, data: any) {
  const url = `${host}/${slug}/concretes/controls/${contractId}/`;

  return post<ConcreteControl>(url, data);
}

export function putControl(controlId: number, data: ConcreteControlRequest) {
  const url = `${host}/${slug}/concretes/${controlId}/`;

  return put<ConcreteControl>(url, data);
}

export function getContract(contractId: number) {
  const url = `/${slug}/contracts/${contractId}/`;

  return get<Contract>(url);
}

export function searchCP(code: number) {
  const url = `/${slug}/concretes/series/cps/`;

  return get<ConcreteTestingBody[]>(url, { code });
}

export function updateCP(id: number, data: ConcreteTestingBody) {
  const url = `${host}/${slug}/concretes/series/cps/${id}/`;

  return put<ConcreteTestingBody>(url, data);
}

export function applications() {
  const url = "/apps/";
  return get<Application[]>(url);
}

export function deleteSeries(id: number) {
  const endpoint = `/${slug}/concretes/series/${id}/`;

  return api.delete(endpoint);
}

export type ConcreteControlPlaces = {
  places: string[];
  optional_places: string[];
};

export function distinctControlPlaces(controlId: number | string) {
  const endpoint = `/${slug}/concretes/v2/controls/${controlId}/unique_places/`;

  return get<ConcreteControlPlaces>(endpoint);
}

export function me() {
  const endpoint = `/me/`;

  return get<Me>(endpoint);
}

export function fastToken() {
  const endpoint = `${host}/${slug}/me/custom_token/`;

  return post<{ access_token: string }>(endpoint, {
    access_token_lifetime: 300,
  });
}

export function getSummary(controlId: number) {
  const endpoint = `/${slug}/concretes/v2/controls/${controlId}/summary/`;

  return get<ConcreteSummary>(endpoint);
}

export function getSummaryFCKs(
  controlId: number,
  page: number,
  series: string
) {
  const endpoint = `/${slug}/concretes/v2/controls/${controlId}/fcks_summary/`;

  return get<ConcreteFCKsSummary>(endpoint, { page, series });
}

export function getUsers(params?: { is_client?: boolean }) {
  const endpoint = `/${slug}/users/`;

  return get<User[]>(endpoint, params);
}

export function putClient(id: number, data: ClientRequest) {
  const endpoint = `${host}/${slug}/clients/${id}/`;

  return put<Client>(endpoint, data);
}

export function postClient(data: ClientRequest) {
  const endpoint = `${host}/${slug}/clients/`;

  return post<Client>(endpoint, data);
}

export function postWork(data: any) {
  let endpoint = `${host}/${slug}/works/`;

  return post<Work>(endpoint, data);
}

export function putWork(id: number, data: any) {
  let endpoint = `${host}/${slug}/works/${id}/`;

  return put<Work>(endpoint, data);
}

export function deleteCPById(id: number) {
  const url = `/${slug}/concretes/v2/cps/${id}/`;

  return _delete(url);
}

export function getCPById(id: number) {
  const url = `/${slug}/concretes/v2/cps/${id}/`;

  return get<ConcreteTestingBody>(url);
}

export async function getControlBreakReport(
  controlId: number,
  options: {
    after: string;
    before: string;
    relative_to: "expected" | "effective";
  }
) {
  const url = `/${slug}/concretes/v2/controls/${controlId}/break_summary/`;

  return get<{ count: number }>(url, options);
}
