import type { Me, Permissions } from "./types";
import { getMe } from "~/store";

export function usePermissions() {
  const route = useRoute();

  function hasPermission(permission: keyof Permissions) {
    const me = getMe();
    const slug = route.params.slug as string;
    const out = me.applications[slug].permissions[permission] || false;

    return out;
  }

  return { hasPermission };
}
