const listeners: {
  [key: string]: { cb: Function; preventDefault: boolean }[];
} = {};
const isMac = navigator.userAgent.includes("Mac");

const initiated = ref<boolean>(false);

export const KEYS_CODES = {
  ONE: { code: "Digit1", label: "1" },
  TWO: { code: "Digit2", label: "2" },
  THREE: { code: "Digit3", label: "3" },
  FOUR: { code: "Digit4", label: "4" },
  FIVE: { code: "Digit5", label: "5" },
  SIX: { code: "Digit6", label: "6" },
  SEVEN: { code: "Digit7", label: "7" },
  EIGHT: { code: "Digit8", label: "8" },
  NINE: { code: "Digit9", label: "9" },
  ZERO: { code: "Digit0", label: "0" },
  A: { code: "KeyA", label: "A" },
  E: { code: "KeyE", label: "E" },
  K: { code: "KeyK", label: "K" },
  G: { code: "KeyG", label: "G" },
  I: { code: "KeyI", label: "I" },
  J: { code: "KeyJ", label: "J" },
  L: { code: "KeyL", label: "L" },
  P: { code: "KeyP", label: "P" },
  U: { code: "KeyU", label: "U" },
  Y: { code: "KeyY", label: "Y" },
  SHIFT: { code: "Shift", label: "Shift" },
  CTRL: { code: "Ctrl", label: "Ctrl" },
  META: { code: "Meta", label: isMac ? "⌘" : "⊞" },
  ALT: { code: "Alt", label: isMac ? "⌥" : "Alt" },
  ESC: { code: "Escape", label: "Esc" },
  BACKSLASH: { code: "Backslash", label: "/" },
  ENTER: { code: "Enter", label: "↵" },
  PERIOD: { code: "Period", label: "." },
  ARROW_DOWN: { code: "ArrowDown", label: "↓" },
  ARROW_UP: { code: "ArrowUp", label: "↑" },
};

function onKeyDown(e: KeyboardEvent) {
  const shift = e.shiftKey;
  const ctrl = e.ctrlKey;
  const meta = e.metaKey;
  const alt = e.altKey;

  let keys = [];
  if (alt) {
    keys.push(KEYS_CODES.ALT.code);
  }
  if (meta) {
    keys.push(KEYS_CODES.META.code);
  }
  if (ctrl) {
    keys.push(KEYS_CODES.CTRL.code);
  }
  if (shift) {
    keys.push(KEYS_CODES.SHIFT.code);
  }

  keys.push(e.code);

  const command = keys.sort().join("::");

  if (command in listeners) {
    const listener = listeners[command];
    listener.forEach((l) => {
      l.cb(e);
      if (l.preventDefault) {
        e.preventDefault();
        e.stopPropagation();
      }
    });
  }
}

export type PlatformKeys = {
  mac: ValueOf<typeof KEYS_CODES>[];
  pc: ValueOf<typeof KEYS_CODES>[];
};

type KeyboardListener = {
  keys: ValueOf<typeof KEYS_CODES>[] | PlatformKeys;
  cb: (e: KeyboardEvent) => void | Promise<void>;
  preventDefault: boolean;
};

type ValueOf<T> = T[keyof T];

function getValuesByOS(keys: PlatformKeys) {
  if (isMac) {
    const values = Object.values(keys.mac) as unknown as ValueOf<
      typeof KEYS_CODES
    >[];
    return values;
  }
  const values = Object.values(keys.pc) as unknown as ValueOf<
    typeof KEYS_CODES
  >[];
  return values;
}

function addListener({ keys, preventDefault, cb }: KeyboardListener) {
  let osKeys: string[] = [];
  if (Array.isArray(keys)) {
    osKeys = keys.map((v) => v.code);
  } else {
    osKeys = getValuesByOS(keys).map((v) => v.code);
  }
  const command = osKeys.sort().join("::");
  if (!(command in listeners)) {
    listeners[command] = [];
  }
  listeners[command].push({
    cb,
    preventDefault,
  });
}

function init() {
  if (!initiated.value) {
    document.addEventListener("keydown", onKeyDown);
  }
  initiated.value = true;
}

export const Keys = {
  NEW_CONCRETE_SERIE: {
    mac: [KEYS_CODES.META, KEYS_CODES.G],
    pc: [KEYS_CODES.CTRL, KEYS_CODES.G],
  },
  SEARCH_CONCRETE: {
    mac: [KEYS_CODES.META, KEYS_CODES.K],
    pc: [KEYS_CODES.CTRL, KEYS_CODES.K],
  },
  SELECT_CONTROL: {
    mac: [KEYS_CODES.META, KEYS_CODES.E],
    pc: [KEYS_CODES.CTRL, KEYS_CODES.E],
  },
};

export function formatShortcut(keys?: PlatformKeys) {
  if (keys) {
    const osKeys = getValuesByOS(keys);

    return osKeys.map((key) => key.label).join("+");
  }

  return "";
}

export default {
  init,
  addListener,
};
