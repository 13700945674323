import { h, render } from "vue";
import Toast from "./Toast.vue";
import type { ToastProps } from "./Toast.vue";

function show(options: ToastProps) {
  const el = document.getElementsByTagName("body");
  const c = document.createElement("div");
  c.setAttribute("id", "toast");
  el[0].appendChild(c);

  const node = h(Toast, options);
  render(node, c);
}

export const toast = {
  show,
};
